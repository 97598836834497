module.exports = new Promise((resolve) => {
      // This check makes sure that the code is executed in the browser
      if (typeof window === 'undefined') {
        return reject(new Error('window is not defined'));
      }
      const script = function createScript(remoteUrl, path, resolve) {
  // This function is called when the remote script is loaded
  const handleScriptLoad = () => {
    const proxy = {
      get: (request) => window[path].get(request),
      init: (arg) => {
        try {
          return window[path].init(arg);
        } catch (e) {
          console.error("remote container already initialized", e);
        }
      },
    };
    resolve(proxy);
  };

  const script = document.createElement("script");
  script.src = remoteUrl;
  script.onload = () => handleScriptLoad(path, resolve);
  script.onerror = () => {
    const message = `Failed to load script from remote path ${path}`;
    console.error(message);
    // need to set error earlier to show it on TL integration
    window.localStorage.setItem(`error ${path}`, message);
    // TODO: handle remote loading error on VCM side for roster application
    const proxy = {
      get: (request) => {
        // If the service is down it will render nothing and avoid the page crash
        // pay attention it won't work for federated store, cause of
        // TypeError: Cannot read properties of undefined (reading 'getStackAddendum')
        // so TODO: this ^ should be handled separately
        return Promise.resolve(() => () => null);
      },
      init: (arg) => {
        return;
      },
    };
    resolve(proxy);
  };
  return script;
}(
       "https://federated-store.qa.crewscheduler.vistaglobal.com/remoteEntry.js",
        'store',
        resolve
      );
      document.head.appendChild(script);
    });;